import Vue from 'vue'
import App from './App.vue'

import Element from 'element-ui'

Vue.config.productionTip = false
Vue.use(Element, {
  size: 'medium' // set element-ui default size
})
new Vue({
  render: h => h(App),
}).$mount('#app')
