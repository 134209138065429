<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: yyq
 * @Date: 2023-02-13 18:56:19
 * @LastEditors: yyq
 * @LastEditTime: 2023-02-14 22:54:08
-->
<template>
  <div id="app">


    <div id="player"></div>
    <!-- <div><div style="text-align: left;margin: 45px 0 8px; 0">无参示例：</div></div>

    <TimeLineCanvas></TimeLineCanvas> -->
    
    <TimeLineCanvas
      ref="time_line"
      @click="clickCanvas"
      @change="changeDate"
      :mark-time="markTime"
      :time-range="timeRange"
      :isAutoPlay="isAutoPlay"
      :startMeddleTime="startMeddleTime"
    />
    <!-- <div style="text-align: left;">
      <input type="button" @click="setTimeRange" value="设置时间区域" />
      <input type="button" @click="setStartMeddleTime" value="设置起点时间" />
      <input type="button" @click="setMarkTime" value="设置区域标签" />
      <input type="button" @click="setIsAutoPlay" value="开启自动播放" />
      <input type="button" @click="stop" value="暂停播放" />
      <input type="button" @click="play" value="开启播放" />
    </div>
    <div style="color: #03A9F4; margin-top: 5px;">{{ msg }}</div> -->
    <el-row type="flex" class="row-bg" justify="center" style="">
      <el-col :span="4">声音:</el-col>
        <el-col :span="5" v-if="!hasSound"><el-button
          @click.native.prevent="openSound()"
          type="success"
          size="small">
          开启
        </el-button></el-col>
        <el-col :span="5" v-else><el-button
          @click.native.prevent="closeSound()"
          type="info"
          size="small">
          关闭
        </el-button></el-col>
        <!-- <el-col :span="7"><span>无效时长</span></el-col> -->
    </el-row>

    <!-- <el-row type="flex" class="row-bg" justify="center" style="margin: 1.2vh;">
        <el-col :span="6"><span style="font-size: large;font-weight: bold;">无效时长</span></el-col>
    </el-row>
    <el-table
    :data="tableData"
    size="small"
    v-show="isShow"
    :stripe = "true"
    >
    <el-table-column
      prop="time"
      label="时间">
    </el-table-column>
    <el-table-column
      prop="reason"
      width="180"
      label="原因">
    </el-table-column>
    <el-table-column
      prop="address"
      width="60"
      label="操作">
      <template slot-scope="scope">
        <el-button
          @click.native.prevent="dopint(scope.$index, tableData)"
          type="text"
          size="small">
          定位
        </el-button>
      </template>
    </el-table-column>
  </el-table> -->
  </div>

</template>

<script>
import TimeLineCanvas from "./components/timeline-canvas.vue";
import moment from "moment";
import axios from "axios";
export default {
  name: "App",
  components: {
    TimeLineCanvas,
  },
  data() {
    return {
      msg: "",
      isAutoPlay: false,
      boxwidth: 0,
      width: 500,
      startMeddleTime: "",
      isShow:false,
      timeRange: [],
      markTime: [],
      player: null,
      urls: {
            realplay: 'ws://10.19.147.22:559/EUrl/q2jQie4',
            talk: 'wss://10.41.163.126:6014/proxy/10.41.163.126:559/EUrl/6gFx47S',
            playback: 'ws://xcjk.hfxnygc.com:559/openUrl/4e3dVHG1'
          },
      playback: {
        startTime: '2023-03-01T00:00:00',
        endTime: '2023-03-01T23:59:59',
        valueFormat: moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
        seekStart: '2021-07-26T12:00:00',
        rate: ''
      },
      tableData: [],
      wgList:[],
      hasSound:false,
      loading:null
    };
  },
  methods: {
    dopint(index,data){
      let dateTime = data[index].date1 + " " + data[index].startTime1
     let startTime =  moment(dateTime).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS); 
     let endTime = this.playback.endTime;
      startTime += 'Z'
      endTime += 'Z'
      this.startMeddleTime = dateTime;
      this.player.JS_Seek(this.player.currentWindowIndex, startTime, endTime).then(
            () => { console.log('seekTo success') },
            e => { console.error(e) }
          )
    },
    async init() {
          // 设置播放容器的宽高并监听窗口大小变化
          window.addEventListener('resize', () => {
            this.player.JS_Resize()
          })

           this.loading = this.$loading({
          lock: true,
          text: '正在加载中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
          // let ids = this.getUrlKey("ids");
          // this.ids = "2038e752a64c430f9713d2fb2d162bb4";
          let {id,time,isLB} =  this.getParam();

          // let response = await axios.get('https://xcx.hfxnygc.com/api/getWGVideo/' + id);
          // let code = response.data.code
          // let data = response.data.data
          // if (code == 200) {
          //   let list = data.list;
          //   this.wgList = list
          //   for (const item of list) {
          //     let  startTime =  moment(item.startTime).format("HH:mm:ss");
          //     let  endTime =  moment(item.endTime).format("HH:mm:ss");
          //     item.time = startTime + "-" + endTime;
          //     item.startTime1 = startTime ;
          //     item.endTime1 = endTime ;
          //     item.date1 = data.data ;
          //   }
          //   this.tableData = list
          if(isLB == 1){
            await this.getMsg(id,time);
          }else{
            await this.getMsgZB(id)
          }
            
           
          },
    // },
    getParam(){
      let geturl = window.location.href 
      let getqyinfo = geturl.split('?')[1]   //qycode=1001&qyname=%E4%BC%81%E4%B8%9A%E5%BF%99
      let getqys = getqyinfo.split('&')  //['qycode=1001', 'qyname=%E4%BC%81%E4%B8%9A%E5%BF%99%E5%95%8A%E5%95%8A%E5%95%8A']
      let id =  getqys[0].split('=')[1]
      let time =  getqys[1].split('=')[1]
      let isLB =  getqys[2].split('=')[1]
      return {id,time,isLB};
    },
    async getMsg(id,time) {
          let response = await axios.get('https://xcx.hfxnygc.com/api/playbackURLs/' + id + "/" + time);
          let code = response.data.code
          let data = response.data.data
          if (code == 200) {
          this.zbList = data;
          
          // let url =  data.url.split("?")
          this.urls.playback = data.url
          // console.log(url)
          
          let time1 =  moment(data.list[0].beginTime).format("YYYY-MM-DD HH:mm:ss");
          this.playback.endTime = moment(data.list[data.list.length - 1].endTime).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
          this.initTime(time,time1);
          this.playbackStart(time)
          // this.play();
        }else{
          alert("视频获取失败")
        }
      },
      async getMsgZB(id) {
          let response = await axios.get('https://xcx.hfxnygc.com/api/previewURLs/' + id);
          let code = response.data.code
          let data = response.data.data
          if (code == 200) {
          
          // let url =  data.url.split("?")
          console.log(data)
          this.urls.realplay = data[0].url
          // console.log(url)
          this.realplay(0)
          // this.play();
        }else{
          alert("视频获取失败")
        }
      },
    createPlayer() {
          this.player = new window.JSPlugin({
            szId: 'player',
            szBasePath: "/bin/",
            iMaxSplit: 4,
            iCurrentSplit: 1,
            openDebug: false,
            oStyle: {
              borderSelect: '#000',
            }
          })
          let that = this;
          // 事件回调绑定
          this.player.JS_SetWindowControlCallback({
            windowEventSelect: function (iWndIndex) {  //插件选中窗口回调
              console.log('windowSelect callback: ', iWndIndex);
              that.isClick = true;
              // that.getRePlayMsg()
              // that.onScreenSingle(iWndIndex);
            },
            pluginErrorHandler: function (iWndIndex, iErrorCode, oError) {  //插件错误回调
              console.log('pluginError callback: ', iWndIndex, iErrorCode, oError);
            },
            windowEventOver: function (iWndIndex) {  //鼠标移过回调
              //console.log(iWndIndex);
            },
            windowEventOut: function (iWndIndex) {  //鼠标移出回调
              //console.log(iWndIndex);
            },
            windowEventUp: function (iWndIndex) {  //鼠标mouseup事件回调
              //console.log(iWndIndex);
            },
            windowFullCcreenChange: function (bFull) {  //全屏切换回调
              console.log('fullScreen callback: ', bFull);
            },
            firstFrameDisplay: function (iWndIndex, iWidth, iHeight) {  //首帧显示回调
              console.log('firstFrame loaded callback: ', iWndIndex, iWidth, iHeight);
            },
            performanceLack: function () {  //性能不足回调
              console.log('performanceLack callback: ');
            }
          });
        },
        openSound() {
          this.hasSound = true;
          this.player.JS_OpenSound().then(
            () => {
              console.log('openSound success')
            },
            e => { console.error(e) }
          )
        },
        closeSound() {
          this.hasSound = false
          this.player.JS_CloseSound().then(
            () => {
              console.log('closeSound success')
            },
            e => { console.error(e) }
          )
        },
    //设置起点时间
    setStartMeddleTime() {
      this.startMeddleTime = "2023-02-10 09:01:00";
    },
    //设置时间区域
    setTimeRange() {
      this.timeRange = "2023-02-10";
      //或
      //this.timeRange=["2023-02-10 00:00:00","2023-02-10 23:59:59"];
      //起点时间根据timeRange计算（timeRange的中间值）
      this.startMeddleTime = null;
    },
    realplay(index) {
          // let { player, mode, urls } = this,
          // index = player.currentWindowIndex,
          let playURL = this.urls.realplay;
          let mode = this.mode;

          this.player.JS_Play(playURL, { playURL, mode }, index).then(
            () => { console.log('realplay success');this.loading.close(); },
            e => {
              console.error(e)
              this.loading.close();
              alert("视频不在线");
            }
          )
        },
        playbackStart(time) {
          // let { player, mode, urls, playback } = this,
          let mode = 1
          let  index = this.player.currentWindowIndex;
           let  playURL = this.urls.playback;
           let startTime =  moment(this.zbList.list[0].beginTime).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS); 
           let endTime = moment(this.zbList.list[this.zbList.list.length - 1 ].endTime).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS); 
          startTime += 'Z'
          endTime += 'Z'

          this.player.JS_Play(playURL, { playURL, mode }, 0, startTime, endTime).then(
            () => {
              console.log('playbackStart success')
              this.playback.rate = 1
              this.setMarkTime(time);
              this.play();
              this.loading.close();
              this.isShow = true
            },
            e => { console.error(e) }
          )
        },
        stopPlay() {
          this.player.JS_Stop().then(
            () => { this.playback.rate = 0; console.log('stop realplay success') },
            e => { console.error(e) }
          )
        },
    //设置区域标签
    setMarkTime(time) {
      this.markTime = [];
      let list = this.zbList.list;
      for (const item of list) {
        let beginTime = moment(item.beginTime).format("YYYY-MM-DD HH:mm:ss");
        let endTime = moment(item.endTime).format("YYYY-MM-DD HH:mm:ss")
        this.markTime.push({
          beginTime,
          endTime,
          bgColor: "#00c521",
          text: "有效",
        }) 
      }

        for (const item of this.wgList) {
          console.log(item)
        this.markTime.push({
          beginTime: time + " " + item.startTime1,
          endTime:time + " " + item.endTime1,
          bgColor: "#FF6666",
          text: "无效",
        })
      }

      console.log(this.markTime)
      // this.markTime = [
      //   {
      //     beginTime: "2023-02-10 06:01:00",
      //     endTime: "2023-02-10 12:02:00",
      //     bgColor: "#FFCC99",
      //     text: "活动",
      //   },
      //   {
      //     beginTime: "2023-02-10 15:01:00",
      //     endTime: "2023-02-10 16:02:00",
      //     bgColor: "#FF6666",
      //     text: "故障",
      //   },
      // ];
    },
    //开启自动播放
    setIsAutoPlay() {
      this.isAutoPlay = true;
    },
    //开启播放
    play() {
      this.$refs.time_line.play();
    },
    //暂停播放
    stop() {
      this.$refs.time_line.stop();
    },
    clickCanvas(date) {
      
      let time = moment(date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
      this.seekTo(time);
      this.play();
    },
    changeDate(date, status) {
      // let time = moment(date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

      // this.seekTo(time);
      // this.play();
      // console.log("changeDate:" + date);
      // this.msg = "选择时间：" + date + "  播放 状 态：" + status;
    },
    seekTo(seekStart) {
          let endTime = this.playback.endTime;
          seekStart += 'Z' ;
          endTime += 'Z' ;
          console.log(seekStart);
          console.log(endTime);
          this.player.JS_Seek(this.player.currentWindowIndex, seekStart, endTime).then(
            () => { console.log('seekTo success') },
            e => { console.error(e) }
          )
        },
        initTime(date,time){
          // let date = date; //moment(new Date()).format("YYYY-MM-DD");
          console.log(date)
          console.log(time)
          this.timeRange = date; // [date + " 00:00:00", date + "  23:59:59"];//"2023-2-10"//;
          this.startMeddleTime = time;
          this.markTime = []
       }
  },
  beforeMount() {
    // let date = "2023-02-10"; //moment(new Date()).format("YYYY-MM-DD");
    // this.timeRange = date; // [date + " 00:00:00", date + "  23:59:59"];//"2023-2-10"//;
    // this.startMeddleTime = date + " 00:00:00";
    // this.markTime = [];
      // {
      //   beginTime: date + " 01:01:00",
      //   endTime: date + " 02:02:00",
      //   bgColor: "#CC3333",
      //   text: "困人",
      // },
      // {
      //   beginTime: date + " 08:01:00",
      //   endTime: date + " 10:02:00",
      //   bgColor: "#FF9966",
      //   text: "非法闯入",
      // },
      // {
      //   beginTime: date + " 15:01:00",
      //   endTime: date + " 16:02:00",
      //   bgColor: "#FF9900",
      //   text: "故障",
      // },
    // ];
  },
  mounted() {
    if(!window.JSPlugin){
      this.loadScript("/bin/h5player.min.js").than(()=>{
        this.$el.style.setProperty('display', 'block')
        this.init()
        this.createPlayer()
      })
    }else{
      this.$el.style.setProperty('display', 'block')
        this.init()
        this.createPlayer()
    }
       
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c5039;
  /* margin-top: 60px; */
}
@media screen and (max-width: 991px) {
      #player {
        width: calc(100vw - 16px);
        height: calc((100vw - 16px) * 5 / 8);
      }
    }

    @media screen and (min-width: 992px) {
      #player {
        width: calc(50vw - 8px);
        height: calc((50vw - 8px) * 5 / 8);
      }
    }
</style>
